
import { Diagram, DiagramConnection, DiagramContent, Flow, FlowStep, ModelConnection, ModelConnectionTechnology, ModelObject, ModelObjectTechnology, modelStatuses } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Animation from '@/components/animation.vue'
import Tabs, { ITab } from '@/components/tabs.vue'
import * as sort from '@/helpers/sort'
import { iconUrlForTheme } from '@/helpers/theme'
import CatalogTechnologyMenu from '@/modules/catalog/components/technology/menu.vue'
import { CatalogModule } from '@/modules/catalog/store'
import { CommentModule } from '@/modules/comment/store'
import DiagramCameraControls from '@/modules/diagram/components/camera-controls.vue'
import DiagramCanvas from '@/modules/diagram/components/canvas.vue'
import DiagramMenu from '@/modules/diagram/components/diagram-menu.vue'
import DiagramInfo from '@/modules/diagram/components/info.vue'
import DiagramLostAlert from '@/modules/diagram/components/lost-alert.vue'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import BackButton from '@/modules/editor/components/back-button.vue'
import DescriptionDialog from '@/modules/editor/components/description-dialog.vue'
import DescriptionEditor from '@/modules/editor/components/description-editor.vue'
import ForwardButton from '@/modules/editor/components/forward-button.vue'
import EditorOverlayBar from '@/modules/editor/components/overlay/bar.vue'
import getFallbackDiagram from '@/modules/editor/helpers/fallback-diagram'
import { EditorModule } from '@/modules/editor/store'
import FlowCancelButton from '@/modules/flow/components/cancel-button.vue'
import FlowContextMenu from '@/modules/flow/components/flow-context-menu/index.vue'
import FlowName from '@/modules/flow/components/name.vue'
import FlowPathPreview from '@/modules/flow/components/path/preview.vue'
import FlowPicker from '@/modules/flow/components/picker.vue'
import FlowStepIndicator from '@/modules/flow/components/step/indicator.vue'
import FlowStepList from '@/modules/flow/components/step/list.vue'
import FlowStepPreview from '@/modules/flow/components/step/preview.vue'
import FlowStepControlBack from '@/modules/flow/components/step-control/back.vue'
import FlowStepControlNext from '@/modules/flow/components/step-control/next.vue'
import FlowStepControlRestart from '@/modules/flow/components/step-control/restart.vue'
import FlowSubflowBack from '@/modules/flow/components/subflow/back.vue'
import FlowSubflow from '@/modules/flow/components/subflow/index.vue'
import { FlowModule } from '@/modules/flow/store'
import HistoryCompact from '@/modules/history/components/history-compact.vue'
import { LandscapeModule } from '@/modules/landscape/store'
import ModelActionsMenu from '@/modules/model/components/actions-menu.vue'
import ModelConnectionDirection from '@/modules/model/components/connections/connection-direction.vue'
import ModelConnectionLabelPosition from '@/modules/model/components/connections/connection-label-position.vue'
import ModelConnectionLower from '@/modules/model/components/connections/connection-lower.vue'
import ModelConnectionReceiver from '@/modules/model/components/connections/connection-receiver.vue'
import ModelConnectionSender from '@/modules/model/components/connections/connection-sender.vue'
import ModelConnectionShape from '@/modules/model/components/connections/connection-shape.vue'
import ModelInDiagrams from '@/modules/model/components/in-diagrams.vue'
import ModelInFlows from '@/modules/model/components/in-flows.vue'
import ModelObjectDependenciesList from '@/modules/model/components/object-dependencies-list/index.vue'
import ModelObjectGroup from '@/modules/model/components/object-group/index.vue'
import ModelObjectLinksList from '@/modules/model/components/objects/links-list/index.vue'
import ModelObjectCaption from '@/modules/model/components/objects/object-caption.vue'
import ModelObjectExternal from '@/modules/model/components/objects/object-external.vue'
import ModelObjectName from '@/modules/model/components/objects/object-name.vue'
import ModelObjectParent from '@/modules/model/components/objects/object-parent.vue'
import ModelObjectPreviewList from '@/modules/model/components/objects/object-preview-list.vue'
import ModelObjectType from '@/modules/model/components/objects/object-type.vue'
import ModelStatus from '@/modules/model/components/status.vue'
import ModelTechnologyList from '@/modules/model/components/technology-list/index.vue'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { SocketModule } from '@/modules/socket/store'
import TagPicker from '@/modules/tag/components/tag-picker/index.vue'
import { TagModule } from '@/modules/tag/store'
import TeamPicker from '@/modules/team/components/picker.vue'
import { TeamModule } from '@/modules/team/store'
import { UserModule } from '@/modules/user/store'
import * as router from '@/plugins/router'

import * as analytics from '../helpers/analytics'
import { VersionModule } from '../store'

const DRAWER_STORAGE_KEY = 'drawer'

const LOCATION_INTERVAL = 10 * 1000

@Component({
  components: {
    Animation,
    BackButton,
    CatalogTechnologyMenu,
    DescriptionDialog,
    DescriptionEditor,
    DiagramCameraControls,
    DiagramCanvas,
    DiagramInfo,
    DiagramLostAlert,
    DiagramMenu,
    EditorOverlayBar,
    FlowCancelButton,
    FlowContextMenu,
    FlowName,
    FlowPathPreview,
    FlowPicker,
    FlowStepControlBack,
    FlowStepControlNext,
    FlowStepControlRestart,
    FlowStepIndicator,
    FlowStepList,
    FlowStepPreview,
    FlowSubflow,
    FlowSubflowBack,
    ForwardButton,
    HistoryCompact,
    ModelActionsMenu,
    ModelConnectionDirection,
    ModelConnectionLabelPosition,
    ModelConnectionLower,
    ModelConnectionReceiver,
    ModelConnectionSender,
    ModelConnectionShape,
    ModelInDiagrams,
    ModelInFlows,
    ModelObjectCaption,
    ModelObjectDependenciesList,
    ModelObjectExternal,
    ModelObjectGroup,
    ModelObjectLinksList,
    ModelObjectName,
    ModelObjectParent,
    ModelObjectPreviewList,
    ModelObjectType,
    ModelStatus,
    ModelTechnologyList,
    Tabs,
    TagPicker,
    TeamPicker
  },
  name: 'VersionViewer'
})
export default class extends Vue {
  catalogModule = getModule(CatalogModule, this.$store)
  commentModule = getModule(CommentModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  socketModule = getModule(SocketModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly canvasRef!: DiagramCanvas
  @Ref() readonly diagramCameraControlsRef!: DiagramCameraControls
  @Ref() readonly editorOverlayBarRef!: EditorOverlayBar
  @Ref() readonly modelObjectPreviewListRef!: ModelObjectPreviewList

  editorError = ''
  canvasError = ''
  initialLoad = true
  initialTracked = false
  editorLoaded = false
  height = window.innerHeight - 57
  windowWidth = 0
  fromVersion = false
  scaleValue: number | null = null

  locationInterval?: number

  drawerSession: string | null = null

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId
  }

  get dataLoaded () {
    const commentsSubscriptionStatus = this.commentModule.commentsSubscriptionStatus
    const connectionsSubscriptionStatus = this.modelModule.connectionsSubscriptionStatus
    const diagramsSubscriptionStatus = this.diagramModule.diagramsSubscriptionStatus
    const diagramContentsSubscriptionStatus = this.diagramModule.diagramContentsSubscriptionStatus
    const diagramGroupsSubscriptionStatus = this.diagramModule.diagramGroupsSubscriptionStatus
    const domainsSubscriptionStatus = this.domainModule.domainsSubscriptionStatus
    const flowsSubscriptionStatus = this.flowModule.flowsSubscriptionStatus
    const objectsSubscriptionStatus = this.modelModule.objectsSubscriptionStatus
    const tagGroupsSubscriptionStatus = this.tagModule.tagGroupsSubscriptionStatus
    const tagsSubscriptionStatus = this.tagModule.tagsSubscriptionStatus

    return (
      this.organizationModule.organizationsListStatus.success &&
      this.organizationModule.organizationUsersListStatus.successInfo.organizationId === this.currentOrganizationId &&

      this.teamModule.teamsListStatus.successInfo.organizationId === this.currentOrganizationId &&

      (this.landscapeModule.landscapeSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId || !!this.landscapeModule.landscapeSubscriptionStatus.loadingInfo.reconnect) &&
      (this.versionModule.versionsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId || !!this.versionModule.versionsSubscriptionStatus.loadingInfo.reconnect) &&

      ((commentsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && commentsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!commentsSubscriptionStatus.loadingInfo.reconnect) &&
      ((connectionsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && connectionsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!connectionsSubscriptionStatus.loadingInfo.reconnect) &&
      ((diagramsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && diagramsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!diagramsSubscriptionStatus.loadingInfo.reconnect) &&
      ((diagramContentsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && diagramContentsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!diagramContentsSubscriptionStatus.loadingInfo.reconnect) &&
      ((diagramGroupsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && diagramGroupsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!diagramGroupsSubscriptionStatus.loadingInfo.reconnect) &&
      ((domainsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && domainsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!domainsSubscriptionStatus.loadingInfo.reconnect) &&
      ((flowsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && flowsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!flowsSubscriptionStatus.loadingInfo.reconnect) &&
      ((objectsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && objectsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!objectsSubscriptionStatus.loadingInfo.reconnect) &&
      ((tagGroupsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && tagGroupsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!tagGroupsSubscriptionStatus.loadingInfo.reconnect) &&
      ((tagsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && tagsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!tagsSubscriptionStatus.loadingInfo.reconnect)
    )
  }

  get loaded () {
    return (this.dataLoaded || this.travelling) && (this.editorLoaded || this.travelling) && this.editorModule.resourceLoaded
  }

  get error () {
    return this.editorError || this.canvasError || this.socketError
  }

  get socketError () {
    return this.socketModule.socketSubscriptionActive ? undefined : (this.socketModule.socketSubscriptionError || this.socketModule.socketError)
  }

  get travelling () {
    return this.versionModule.travelling
  }

  get drawer () {
    return this.$queryValue('drawer')
  }

  get objectTab () {
    return this.$queryValue('object_tab')
  }

  get currentModelHandleId () {
    return this.$queryValue('model')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get currentFlowStepId () {
    return this.$queryValue('flow_step')
  }

  get currentFlowPathIds () {
    return this.$queryArray('flow_path')
  }

  get currentFlowParentHandleId () {
    return this.$queryArray('flow_parent')?.slice(-1)?.[0]
  }

  get currentObjectIds () {
    return this.$queryArray('object')
  }

  get currentConnectionIds () {
    return this.$queryArray('connection')
  }

  get overlayTab () {
    return this.$queryValue('overlay_tab')
  }

  get overlayGroupId () {
    return this.$queryValue('overlay_group')
  }

  get overlayIdsPinned () {
    return this.$queryArray('overlay_pin')
  }

  get overlayIdsHidden () {
    return this.$queryArray('overlay_hide')
  }

  get overlayIdsFocused () {
    return this.$queryArray('overlay_focus')
  }

  get drawerExpanded () {
    return !!this.currentFlow && this.drawer === 'expanded'
  }

  get drawerObjectVisible () {
    return !!this.currentModelObjectIds.length || !!this.currentModelConnectionIds.length
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.$params.versionId && o.landscapeId === this.currentLandscape?.id)
  }

  get currentVersionModel () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentVersion?.modelHandleId)
  }

  get currentVersionLatest () {
    return this.currentVersion?.tags.includes('latest')
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape?.organizationId)
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramModelObject () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentModelHandleId)
  }

  get currentDiagramModelParent () {
    return this.currentDiagramModelObject?.parentId ? this.modelModule.objects[this.currentDiagramModelObject.parentId] : undefined
  }

  get currentOtherDiagrams () {
    const currentDiagramModelObject = this.currentDiagramModelObject
    return Object.values(this.diagramModule.diagrams).filter(o => !o.parentId && o.modelId === currentDiagramModelObject?.id)
  }

  get currentDiagramGroup () {
    return this.currentDiagram?.groupId ? this.diagramModule.diagramGroups[this.currentDiagram.groupId] : undefined
  }

  get currentFlows () {
    return Object.values(this.flowModule.flows).filter(o => o.diagramId === this.currentDiagram?.id).sort((a, b) => a.name.localeCompare(b.name))
  }

  get currentFlow () {
    return this.currentFlows.find(o => o.handleId === this.currentFlowHandleId)
  }

  get currentFlowStep () {
    return this.currentFlowStepId ? this.currentFlow?.steps[this.currentFlowStepId] : undefined
  }

  get currentFlowPathSteps () {
    return Object
      .values(this.currentFlow?.steps || {})
      .filter(o => o.type?.endsWith('-path') && o.index === this.currentFlowStep?.index)
      .sort(sort.pathIndex)
  }

  get currentFlowParent () {
    return Object.values(this.flowModule.flows).find(o => o.handleId === this.currentFlowParentHandleId)
  }

  get currentModelObjectIds () {
    return this.currentObjectIds.map(o => this.currentDiagramContent?.objects[o]?.modelId).filter((o): o is string => !!o)
  }

  get currentModelConnectionIds () {
    return this.currentConnectionIds.map(o => this.currentDiagramContent?.connections[o]?.modelId).filter((o): o is string => !!o)
  }

  get currentObject () {
    return this.currentObjectIds[0] ? this.currentDiagramContent?.objects[this.currentObjectIds[0]] : undefined
  }

  get currentConnections () {
    return this.currentConnectionIds.map(o => this.currentDiagramContent?.connections[o]).filter((o): o is DiagramConnection => !!o)
  }

  get currentConnection (): DiagramConnection | undefined {
    return this.currentConnections[0]
  }

  get currentConnectionLower () {
    const diagramConnection = this.currentModelConnection && this.currentDiagram ? this.currentModelConnection.diagrams[this.currentDiagram.id] : undefined
    return diagramConnection && this.currentModelConnection && (this.currentModelConnection?.originId !== diagramConnection.originModelId || this.currentModelConnection.targetId !== diagramConnection.targetModelId)
  }

  get currentModelObject () {
    return this.currentObject ? this.modelModule.objects[this.currentObject.modelId] : undefined
  }

  get currentModelConnection () {
    return this.currentConnection?.modelId ? this.modelModule.connections[this.currentConnection.modelId] : undefined
  }

  get currentModelObjects () {
    return this.currentModelObjectIds.map(o => this.modelModule.objects[o]).filter((o): o is ModelObject => !!o)
  }

  get currentModelTechnologies () {
    const technologies = [...this.currentModelObjects, ...this.currentModelConnections].reduce((p, c) => ({
      ...p,
      ...c.technologies
    }), {} as Record<string, ModelObjectTechnology | ModelConnectionTechnology>)
    return Object
      .values(technologies)
      .map(o => ({
        ...o,
        icon: iconUrlForTheme(o)
      }))
      .sort(sort.index)
  }

  get currentModelConnections () {
    return this.currentModelConnectionIds.map(o => this.modelModule.connections[o]).filter((o): o is ModelConnection => !!o)
  }

  get currentModelObjectsLinksCount () {
    return Object.keys(this.currentModelObject?.links || {}).length
  }

  get currentModelObjectsLinksValid () {
    return Object
      .values(this.currentModelObject?.links || {})
      .filter(o => 'status' in o && o.status === 'valid').length
  }

  get currentModelObjectsLinksInvalid () {
    return Object
      .values(this.currentModelObject?.links || {})
      .filter(o => 'status' in o && o.status === 'invalid').length
  }

  get drawerObjectMode () {
    if (this.currentObjectIds.length + this.currentConnectionIds.length > 1) {
      return 'multiple'
    } else if (this.currentModelObjectIds.length === 1 && this.currentModelObject) {
      if (this.currentModelObject.name.includes('\n') || this.currentModelObject.icon) {
        return 'details-two-line'
      } else {
        return 'details-one-line'
      }
    } else if (this.currentModelConnectionIds.length === 1 && this.currentModelConnection) {
      return this.currentModelConnection.name.includes('\n') ? 'details-connection-double' : 'details-connection-single'
    }
  }

  get tagBarWidth (): number {
    if (this.drawerObjectVisible) {
      return this.windowWidth - 320 - 24 - 320 - 24
    } else {
      return this.windowWidth - 24 - 320 - 24
    }
  }

  get drawerWidth (): number {
    if (this.drawerExpanded) {
      return 320
    } else {
      return 0
    }
  }

  get drawerHeight (): number {
    if (this.drawerExpanded) {
      return this.height - 48 - 2
    } else if (this.currentFlow) {
      let height = 80
      if (this.currentFlowParent) {
        height += 32
      }
      if (this.currentFlowStep) {
        height += 52
      }
      return height
    } else {
      return 40
    }
  }

  get drawerObjectWidth (): number {
    if (this.drawerObjectVisible) {
      return 320
    } else {
      return 0
    }
  }

  get showObjectTab () {
    return this.currentObjectIds.length === 1 && !this.currentConnectionIds.length && !!this.currentObject
  }

  get objectTabs () {
    const tabs: ITab[] = [
      {
        id: 'details',
        text: 'Details',
        to: {
          query: this.$setQuery({
            object_tab: 'details'
          })
        }
      },
      {
        id: 'connections',
        text: 'Connections',
        to: {
          query: this.$setQuery({
            object_tab: 'connections'
          })
        }
      },
      {
        id: 'history',
        text: 'History',
        to: {
          query: this.$setQuery({
            object_tab: 'history'
          })
        }
      }
    ]
    return tabs
  }

  get pageTitle () {
    const sections: string[] = []
    if (this.currentDiagram) {
      sections.push(this.currentDiagram.name)
    }
    if (this.currentLandscape) {
      sections.push(this.currentLandscape.name)
    }
    return sections
  }

  @Watch('pageTitle')
  onPageTitleChanged (sections: string[]) {
    router.setTitle(sections)
  }

  @Watch('currentVersionId')
  onCurrentVersionIdChanged () {
    this.initialLoad = true
    this.initialTracked = false
    this.locationUpdate()
  }

  @Watch('$route')
  onRouteChange () {
    const query: any = {}

    if (this.objectTab && !this.objectTabs.some(o => o.id === this.objectTab)) {
      query.object_tab = this.objectTabs[0].id
    }

    if (this.currentFlow) {
      let flowPathUnion: string[] = []
      let flowPathRemove: string[] = []

      const groupedPaths = Object
        .values(this.currentFlow.steps)
        .filter(o => o.type?.endsWith('-path'))
        .reduce<FlowStep[][]>((p, c) => {
          const existingGroup = p.find(o => c.index === o[0].index)
          if (existingGroup) {
            existingGroup.push(c)
            return p
          } else {
            return [...p, [c]]
          }
        }, [])
        .map(o => o.sort(sort.pathIndex))

      flowPathUnion = groupedPaths.filter(o => o.every(p => !this.currentFlowPathIds.includes(p.id))).map(o => o[0].id)
      flowPathRemove = this.currentFlowPathIds.filter(o => groupedPaths.every(g => g.every(s => s.id !== o)))

      if (flowPathUnion.length || flowPathRemove.length) {
        query.flow_path = {
          ...this.$unionQueryArray(...flowPathUnion),
          ...this.$removeQueryArray(...flowPathRemove)
        }
      }

      const currentFlowPathIds = [...this.currentFlowPathIds, ...flowPathUnion].filter(o => !flowPathRemove.includes(o))
      const currentFlowSteps = Object
        .values(this.currentFlow.steps)
        .filter(o => !o.type?.endsWith('-path') && (!o.pathId || currentFlowPathIds.includes(o.pathId)))
        .reduce<FlowStep[][]>((p, c) => {
          const existingGroup = p.find(o => c.index === o[0].index)
          if (existingGroup) {
            existingGroup.push(c)
            return p
          } else {
            return [...p, [c]]
          }
        }, [])
        .sort((a, b) => sort.index(a[0], b[0]))
        .map(o => o.sort(sort.pathIndex))
        .flat()

      if (currentFlowSteps.every(o => o.id !== this.currentFlowStepId)) {
        const currentStep = this.currentFlowStepId ? this.currentFlow.steps[this.currentFlowStepId] : undefined

        const nextPathStep = currentStep?.pathId ? currentFlowSteps.find(o => o.index === currentStep.index && o.pathId && o.pathIndex !== null && o.pathIndex === currentStep.pathIndex) : undefined
        const nextPathPreviousStep = currentStep?.pathId ? [...currentFlowSteps].reverse().find(o => o.index === currentStep.index && o.pathId && o.pathIndex !== null && currentStep.pathIndex !== null && o.pathIndex <= currentStep.pathIndex) : undefined
        const previousStep = currentStep ? [...currentFlowSteps].reverse().find(o => o.index <= currentStep.index) : undefined

        const fallbackStep = nextPathStep || nextPathPreviousStep || previousStep || currentFlowSteps[0]
        if (fallbackStep) {
          query.flow_step = fallbackStep.id
        }
      }
    }

    this.$replaceQuery(query)
  }

  @Watch('showObjectTab')
  onShowObjectTabChanged (newVal?: boolean, oldVal?: boolean) {
    setImmediate(() => {
      if (newVal !== oldVal) {
        const query: any = {}

        query.expanded_connection = undefined
        query.expanded_connection_tab = undefined

        if (newVal && !this.objectTab) {
          query.object_tab = this.objectTabs[0].id
        } else if (!newVal && this.objectTab) {
          query.object_tab = undefined
        }

        this.$replaceQuery(query)
      }
    })
  }

  @Watch('currentDiagram')
  onCurrentDiagramChanged (currentDiagram?: Diagram, prevDiagram?: Diagram) {
    this.diagramModule.setDiagramContentStagedId(currentDiagram?.status === 'draft' ? currentDiagram.id : null)

    if (this.initialTracked && this.editorLoaded && currentDiagram && currentDiagram.version >= 0 && (currentDiagram.id !== prevDiagram?.id || prevDiagram?.version === -1)) {
      this.trackDiagramEvent(currentDiagram)
      this.locationUpdate()
    }

    if (prevDiagram && !currentDiagram && this.currentModelHandleId && !this.travelling && this.dataLoaded) {
      const fallbackDiagram = getFallbackDiagram({
        diagram: this.currentDiagram,
        diagramGroupId: this.currentDiagramGroup?.id,
        diagramGroups: Object.values(this.diagramModule.diagramGroups),
        diagrams: Object.values(this.diagramModule.diagrams),
        model: this.currentDiagramModelObject,
        modelParent: this.currentDiagramModelParent
      })

      if (fallbackDiagram) {
        this.$replaceQuery({
          connection: undefined,
          diagram: fallbackDiagram.diagram.handleId,
          flow: undefined,
          flow_parent: undefined,
          flow_path: undefined,
          flow_step: undefined,
          model: fallbackDiagram.model.handleId,
          object: undefined,
          scale: undefined,
          x1: undefined,
          x2: undefined,
          y1: undefined,
          y2: undefined
        })
      } else {
        this.$router.push({
          name: 'diagrams',
          params: {
            landscapeId: this.currentLandscapeId,
            versionId: this.currentVersionId
          }
        })
      }
    }
  }

  @Watch('currentFlow')
  onCurrentFlowChanged (currentFlow?: Flow, prevFlow?: Flow) {
    if (this.initialTracked && this.editorLoaded && currentFlow && currentFlow.version >= 0 && (currentFlow.id !== prevFlow?.id || prevFlow?.version === -1)) {
      this.trackFlowEvent(currentFlow)
    }
  }

  @Watch('dataLoaded')
  onDataLoadedChanged (dataLoaded: boolean) {
    if (dataLoaded) {
      this.loadEditor()
    } else {
      this.editorLoaded = false
    }
  }

  @Watch('currentOrganizationId')
  onCurrentOrganizationIdChanged (currentOrganizationId?: string) {
    if (currentOrganizationId && this.organizationModule.organizationUsersListStatus.loadingInfo.organizationId !== currentOrganizationId && this.organizationModule.organizationUsersListStatus.successInfo.organizationId !== currentOrganizationId) {
      this.organizationModule.organizationUsersList(currentOrganizationId)
    }
    if (currentOrganizationId && this.teamModule.teamsListStatus.loadingInfo.organizationId !== currentOrganizationId && this.teamModule.teamsListStatus.successInfo.organizationId !== currentOrganizationId) {
      this.teamModule.teamsList(currentOrganizationId)
    }
  }

  @Watch('drawer')
  onDrawerChanged (drawer?: string | null) {
    sessionStorage.setItem(DRAWER_STORAGE_KEY, JSON.stringify(drawer))
  }

  created () {
    this.fromVersion = this.travelling

    this.resize()
  }

  mounted () {
    router.setTitle(this.pageTitle)

    this.resize()

    if (!this.editorModule.resourceLoaded) {
      this.editorModule.loadResources()
    }

    if (this.currentOrganizationId && this.organizationModule.organizationUsersListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.organizationModule.organizationUsersListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.organizationModule.organizationUsersList(this.currentOrganizationId)
    }
    if (this.currentOrganizationId && this.teamModule.teamsListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.teamModule.teamsListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.teamModule.teamsList(this.currentOrganizationId)
    }

    if (this.dataLoaded) {
      this.loadEditor()
    }

    try {
      sessionStorage.setItem(DRAWER_STORAGE_KEY, JSON.stringify(this.drawer))
    } catch (err) {
      console.error(err)
    }
  }

  destroyed () {
    this.diagramModule.setDiagramContentStagedId(null)

    clearInterval(this.locationInterval)
  }

  async loadEditor () {
    try {
      let diagram: Diagram | undefined
      let diagramContent: DiagramContent | undefined
      let model: ModelObject | undefined
      let objectsIds: string[] | undefined
      let connectionIds: string[] | undefined
      let flowHandleId: string | undefined
      let overlayGroupId: string | undefined
      let overlayIdsHidden: string[] | undefined
      let overlayIdsPinned: string[] | undefined
      let overlayIdsFocused: string[] | undefined

      if (this.currentModelHandleId) {
        model = this.modelModule.objects[this.currentModelHandleId] || Object.values(this.modelModule.objects).find(o => o.handleId === this.currentModelHandleId)
      }
      if (this.currentDiagramHandleId) {
        diagram = this.diagramModule.diagrams[this.currentDiagramHandleId] || Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
        diagramContent = this.diagramModule.diagramContents[this.currentDiagramHandleId] || Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
      }
      if (!model) {
        model = (diagram ? this.modelModule.objects[diagram.modelId] : undefined) || Object.values(this.modelModule.objects).find(o => o.type === 'root')
      }
      if (!model) {
        throw new Error('Could not find entry model')
      }

      const explore = await this.diagramModule.diagramsExplore({
        diagramId: diagram?.id,
        landscapeId: this.currentLandscapeId,
        modelId: model.id,
        versionId: this.$params.versionId
      })
      if (!explore) {
        throw new Error('Could not find entry diagram')
      }

      diagram = explore.diagram
      diagramContent = explore.diagramContent

      this.diagramModule.diagramAction({
        action: this.initialLoad ? 'initial-load' : 'load',
        diagramId: diagram.id,
        landscapeId: this.currentLandscapeId,
        versionId: this.currentVersionId
      })

      const technologies = this.modelModule.technologies

      if (this.currentObjectIds) {
        objectsIds = Object
          .values(diagramContent.objects)
          .filter(o => this.currentObjectIds.includes(o.id) || this.currentObjectIds.includes(o.modelId))
          .map(o => o.id)
      }
      if (this.currentConnectionIds) {
        connectionIds = Object
          .values(diagramContent.connections)
          .filter(o => this.currentConnectionIds.includes(o.id) || (o.modelId && this.currentConnectionIds.includes(o.modelId)))
          .map(o => o.id)
      }
      if (this.currentFlowHandleId) {
        flowHandleId = this.flowModule.flows[this.currentFlowHandleId]?.handleId || Object.values(this.flowModule.flows).find(o => o.diagramId === diagram?.id && o.handleId === this.currentFlowHandleId)?.handleId
      }
      if (this.overlayTab === 'tags' && this.overlayGroupId) {
        overlayGroupId = this.tagModule.tagGroups[this.overlayGroupId]?.handleId || Object.values(this.tagModule.tagGroups).find(o => o.handleId === this.overlayGroupId)?.handleId
        if (!overlayGroupId) {
          overlayGroupId = Object.values(this.tagModule.tagGroups).reduce((p, c) => p.index > c.index ? c : p)?.handleId
        }
      } else if (this.overlayTab === 'technology' && this.overlayGroupId) {
        overlayGroupId = this.catalogModule.technologyGroups[this.overlayGroupId] || 'all'
      } else if (this.overlayTab === 'status' && this.overlayGroupId) {
        overlayGroupId = 'all'
      } else if (this.overlayTab === 'teams' && this.overlayGroupId) {
        overlayGroupId = 'all'
      }
      if (this.overlayIdsHidden.length) {
        overlayIdsHidden = [
          ...Object.values(this.tagModule.tags).filter(o => this.overlayIdsHidden.includes(o.handleId) || this.overlayIdsHidden.includes(o.id)).map(o => o.handleId),
          ...Object.values(technologies).filter(o => this.overlayIdsHidden.includes(o.id)).map(o => o.id),
          ...Object.values(modelStatuses).filter(o => this.overlayIdsHidden.includes(o.id)).map(o => o.id),
          ...this.teamModule.teams.filter(o => this.overlayIdsHidden.includes(o.id)).map(o => o.id)
        ]
      }
      if (this.overlayIdsPinned.length) {
        overlayIdsPinned = [
          ...Object.values(this.tagModule.tags).filter(o => this.overlayIdsPinned.includes(o.handleId) || this.overlayIdsPinned.includes(o.id)).map(o => o.handleId),
          ...Object.values(technologies).filter(o => this.overlayIdsPinned.includes(o.id)).map(o => o.id),
          ...Object.values(modelStatuses).filter(o => this.overlayIdsPinned.includes(o.id)).map(o => o.id),
          ...this.teamModule.teams.filter(o => this.overlayIdsPinned.includes(o.id)).map(o => o.id)
        ]
      }
      if (this.overlayIdsFocused.length) {
        overlayIdsFocused = [
          ...Object.values(this.tagModule.tags).filter(o => this.overlayIdsFocused.includes(o.handleId) || this.overlayIdsFocused.includes(o.id)).map(o => o.handleId),
          ...Object.values(technologies).filter(o => this.overlayIdsFocused.includes(o.id)).map(o => o.id),
          ...Object.values(modelStatuses).filter(o => this.overlayIdsFocused.includes(o.id)).map(o => o.id),
          ...this.teamModule.teams.filter(o => this.overlayIdsFocused.includes(o.id)).map(o => o.id)
        ]
      }

      this.diagramModule.setDiagramContentStagedId(diagram.status === 'draft' ? diagram.id : null)

      let drawer: string | undefined
      try {
        const drawerSession = sessionStorage.getItem(DRAWER_STORAGE_KEY)
        if (drawerSession === null) {
          drawer = this.drawer || undefined
        } else {
          drawer = JSON.parse(drawerSession) || undefined
        }
      } catch (err) {
        console.error(err)
      }

      await this.$replaceQuery({
        connection: connectionIds,
        diagram: diagram.handleId,
        drawer,
        flow: flowHandleId,
        model: model.handleId,
        object: objectsIds,
        object_tab: this.showObjectTab ? this.objectTab || 'details' : this.objectTab,
        overlay_focus: overlayIdsFocused?.length ? overlayIdsFocused : undefined,
        overlay_group: overlayGroupId,
        overlay_hide: overlayIdsHidden?.length ? overlayIdsHidden : undefined,
        overlay_pin: overlayIdsPinned?.length ? overlayIdsPinned : undefined,
        overlay_tab: this.overlayTab || 'tags'
      })

      this.editorError = ''
      this.editorLoaded = true
      this.initialLoad = false

      if (!this.initialTracked) {
        this.initialTracked = true

        if (this.currentDiagram) {
          this.trackDiagramEvent(this.currentDiagram)
        }
        if (this.currentFlow) {
          this.trackFlowEvent(this.currentFlow)
        }
      }

      clearInterval(this.locationInterval)
      this.locationInterval = window.setInterval(this.locationUpdate.bind(this), LOCATION_INTERVAL)
      this.locationUpdate()
    } catch (err: any) {
      this.editorError = err.message
      throw err
    }
  }

  locationUpdate () {
    if (!this.currentDiagram || !this.userModule.user || !this.currentOrganization || !this.currentOrganization.userIds.includes(this.userModule.user.id)) {
      return
    }

    this.editorModule.editorLocationUpdate({
      landscapeId: this.currentDiagram.landscapeId,
      location: {
        diagramId: this.currentDiagram.id,
        versionId: this.currentVersionId
      }
    })
  }

  trackDiagramEvent (diagram: Diagram) {
    if (this.currentOrganizationId && this.currentVersion && this.currentVersionLatest !== undefined) {
      analytics.versionDiagramScreen.track(this, {
        diagramType: diagram.type,
        landscapeId: [this.currentLandscapeId],
        organizationId: [this.currentOrganizationId],
        versionCreatedAt: this.currentVersion.createdAt,
        versionLatest: this.currentVersionLatest,
        versionModelId: this.currentVersionModel?.id || null,
        versionModelType: this.currentVersionModel?.type || null,
        versionName: this.currentVersion.name
      })

      this.diagramModule.diagramView({
        diagramId: diagram.id,
        landscapeId: this.currentLandscapeId,
        versionId: this.currentVersionId
      })
    }
  }

  trackFlowEvent (flow: Flow) {
    this.flowModule.flowView({
      flowId: flow.id,
      landscapeId: this.currentLandscapeId,
      versionId: this.currentVersionId
    })
  }

  trackDrawerEvent (expanded = true) {
    if (this.currentOrganizationId && this.currentDiagram) {
      if (expanded) {
        analytics.versionDrawerExpand.track(this, {
          diagramType: this.currentDiagram.type,
          landscapeId: [this.currentLandscapeId],
          organizationId: [this.currentOrganizationId]
        })
      } else {
        analytics.versionDrawerCollapse.track(this, {
          diagramType: this.currentDiagram.type,
          landscapeId: [this.currentLandscapeId],
          organizationId: [this.currentOrganizationId]
        })
      }
    }
  }

  resize () {
    this.height = window.innerHeight - 56
    this.windowWidth = window.innerWidth

    this.canvasRef?.resize()
    this.modelObjectPreviewListRef?.resize()
    this.editorOverlayBarRef?.resize()
  }
}
